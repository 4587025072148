import React from "react"
import { graphql } from "gatsby"
import {
  Helmet,
  Banner,
  ContactUsEmailLink,
  ContactUsPhoneLink,
  Layout,
  Theme,
  Container,
  Row,
  Box,
  Intro,
  routesObject
} from "@life-without-barriers/react-components"
import { IGatsbyImageData } from "gatsby-plugin-image"
import { Site } from "@life-without-barriers/gatsby-common"
import Form, {
  FormFields,
  FieldsProps,
  DataFormatters
} from "@life-without-barriers/shared-contact-form"

const { Name, Postcode, ContactMethodToggleField, MessageTextArea } = FormFields

const { agedCareThemeFull } = Theme

interface Props {
  location: { pathname: string }
  data: {
    site: Site
    bannerImage: IGatsbyImageData
  }
}

const PageForm = (
  <Form
    fieldFactory={(fieldsProps: FieldsProps) => [
      <Name key="Name" {...fieldsProps} />,
      <Postcode key="Postcode" {...fieldsProps} />,
      <ContactMethodToggleField
        key="ContactMethodToggleField"
        initialContactMethod="phone"
        {...fieldsProps}
      />,
      <MessageTextArea
        key="MessageTextArea"
        label="What services are you looking for?"
        {...fieldsProps}
      />
    ]}
    formId="veterans-contact-form"
    formKind="aged-care"
    formTitle="Talk to a specialist"
    initialValues={{
      contactMethod: "phone",
      enquiryType: "Veterans Home Care Program"
    }}
    formDataFormatter={DataFormatters.nestContactDetailsFormatter}
  />
)

const VeteransHomeCarePage = ({
  location,
  data: {
    site: { siteMetadata },
    bannerImage
  }
}: Props) => {
  return (
    <Layout
      theme={agedCareThemeFull}
      fixedCTAPhoneNumber="1800792359"
      fixedCTAContact={PageForm}
    >
      <div>
        <Helmet
          title={`Veterans' Home Care Program | ${siteMetadata.title}`}
          description="Our program is available for eligible veterans or widows / widowers."
          image={bannerImage}
          siteUrl={siteMetadata.siteUrl}
          path={location.pathname}
          themeColor={agedCareThemeFull.medium}
        />
        <Banner
          title="Veterans' Home Care Program"
          image={bannerImage}
          breadcrumbs={[
            routesObject.home,
            {
              to: "/services/aged-care/",
              text: "Aged care services",
              title: "Aged care services"
            }
          ]}
        />
        <Container>
          <Row className="flex-wrap">
            <Box className="w-100 w-seven-twelfths-l layout-gap">
              <Intro
                text="Our Veterans’ Home Care Program is available for eligible
                veterans or widows / widowers."
              />
              <Row topMargin="standard">
                <Box>
                  <p>
                    Our Veterans’ Home Care Program is available for eligible
                    veterans or widows / widowers. The service provides a range
                    of personal care, domestic assistance, safety-related home
                    and garden maintenance, respite and social assistance.
                    Veterans’ Home Care is available through Life Without
                    Barriers in selected areas at New South Wales, Queensland,
                    Victoria and Western Australia.
                  </p>
                  <p>
                    If you would like to find out more about our services
                    contact us now on by phone, email or fill out our enquiry
                    form.
                  </p>
                  <div className="flex-ns mt4 mt2-l">
                    <div className="w-100 w-50-ns">
                      <ContactUsPhoneLink phoneNumber="1800792359" />
                    </div>
                    <div className="w-100 w-50-ns">
                      <ContactUsEmailLink
                        text="agedcareenquiries@lwb.org.au"
                        link="mailto:agedcareenquiries@lwb.org.au"
                      />
                    </div>
                  </div>
                </Box>
              </Row>
            </Box>
            <Box className="w-100 w-third-l">
              <div className="mt4 mt0-l">{PageForm}</div>
            </Box>
          </Row>
        </Container>
      </div>
    </Layout>
  )
}

export const query = graphql`
  {
    bannerImage: file(
      relativePath: { regex: "/img-veterans-home-care-header.png/" }
    ) {
      childImageSharp {
        gatsbyImageData(width: 1200, quality: 90)
      }
    }
    site {
      siteMetadata {
        title
        siteUrl
      }
    }
  }
`

export default VeteransHomeCarePage
